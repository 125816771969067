import React, {Fragment, useState, useEffect} from 'react';
import {useForm} from 'react-hook-form';
import Form from 'react-bootstrap/Form';
import DOMPurify from 'dompurify';

import { API, graphqlOperation } from 'aws-amplify';

import {updateLPPost} from '../../../../graphql/mutations';

//import Editor from '../../Utils/Editor';
import { Editor } from '@tinymce/tinymce-react';
import Spinner from '../../../Utils/Spinner';

const EditPost = (props: any) => {
    const { register, handleSubmit, setValue } = useForm();
    const [data, setData] = useState<null | any>();
    const [isLoading, setIsLoading] = useState<boolean>(false);

    useEffect(() => {
      if(props.content) {
        setData(props.content);
      }
    }, [props]);

    const onChange = (e: any) => {
        setValue(e.target.name, e.target.value)
      }

    const onSubmit = async (data: any) => {
     
      setIsLoading(true);
      const input = {
        id: props.id,
        title: props.title,
        image: data.imgUrl,
        content: DOMPurify.sanitize(data.content)
      }
      await API.graphql(graphqlOperation(updateLPPost, {input}));
      setIsLoading(false);
    }
    return (
        <Form>
            <Form.Group>
            <Form.Label>URL naar een afbeelding</Form.Label>
            <Form.Control type="text" name="imgUrl" onChange={onChange} ref={register}></Form.Control>
            </Form.Group>
            <Form.Group>
                {/* <Form.Label><h1 c>{props.title}</h1></Form.Label> */}
                <Editor
              ref={node => register({name: 'content'})}
              apiKey="5j17gl15qabh112rj48lmha0tdncf9lq53glnndwzhw38qr7"
              init={{
                height: 500,
                menubar: false,
                plugins: [
                  'advlist autolink lists link image charmap print preview anchor',
                  'searchreplace visualblocks code fullscreen',
                  'insertdatetime media table paste imagetools wordcount'
                ],
                toolbar: 'insertfile undo redo | styleselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link image',
              }}
              initialValue={data}
              onEditorChange={(content: any, editor: any) => {
                setValue('content', content);
              }}
               />
            </Form.Group>
            <Form.Group>
                <button className="btn btn-success" onClick={handleSubmit(onSubmit)} type="submit" disabled={isLoading}>
                Update {props.title}
                </button>
                {isLoading && <Spinner />}
            </Form.Group>
            </Form>
    )
}

export default EditPost
