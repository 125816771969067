import React, {useState, useEffect} from 'react';
import { API, graphqlOperation } from 'aws-amplify'
import {listLPPosts} from '../../../graphql/queries';
import {onCreateLPPost, onDeleteLPPost} from '../../../graphql/subscriptions';
import Container from 'react-bootstrap/Container';

import {Subscription} from 'rxjs';

import Editor from './Editor';
import Viewer from './Viewer';

import Spinner from '../../Utils/Spinner';

const PostItems = () => {
    const [allPosts, setAllPosts] = useState<any[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true);
            const result: any = await API.graphql(graphqlOperation(listLPPosts));
            setAllPosts(result.data.listLPPosts.items);
            setIsLoading(false);
        }
        fetchData();
    }, []);

    // onCreate Listener
    useEffect(()=> {
        const createPostListener: Subscription = (API.graphql(graphqlOperation(onCreateLPPost)) as any).subscribe({
            next: (postData: any) => {
                const postItem = postData.value.data.onCreateLPPost;
                const prevPost = allPosts?.filter( (post: any) => post.id !== postItem.id);
                const updatedPosts: any[] = [postItem, ...prevPost];
                setAllPosts(updatedPosts);
            }
        });
    return () => createPostListener.unsubscribe();
    }, [allPosts]);

    // onDelete Listener
    useEffect(() => {
        const deletePostListener: Subscription = (API.graphql(graphqlOperation(onDeleteLPPost)) as any).subscribe({
            next: (postData: any) => {
                const deletedPost = postData.value.data.onDeleteLPPost;
                const updatedPost: any[] = allPosts?.filter((post: any) => post.id !== deletedPost.id);
                setAllPosts(updatedPost);
            }
        })
    return () => deletePostListener.unsubscribe();
    }, [allPosts]);

    return (
        <Container className="mb-3 mt-3">
            <Editor />
            {isLoading && <Spinner />}
            {allPosts.map((item, i) => {
                return <Viewer key={i} id={item.id} title={item.title} post={item} />
            })}
        </Container>
    )
}

export default PostItems;