import React, {useState} from 'react';
import {useForm} from 'react-hook-form';
import DOMPurify from 'dompurify';
import { API, graphqlOperation } from 'aws-amplify';
import {createLPPost} from '../../../../graphql/mutations';

import { Editor } from '@tinymce/tinymce-react';

import Form from 'react-bootstrap/Form';
import Spinner from '../../../Utils/Spinner';

const PostEditor = () => {
    const { register, handleSubmit, setValue, reset } = useForm();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const onChange = (e: any) => {
        setValue(e.target.name, e.target.value)
      }

      const onSubmit = async (data: any) => {
        setIsLoading(true);
        const input = {
            title: data.title,
            image: data.imgUrl,
            content: DOMPurify.sanitize(data.content),
        }
        await API.graphql(graphqlOperation(createLPPost, {input}));
        reset();
        setIsLoading(false);
      }
    return (
        <Form>
          <Form.Group>
            <Form.Label>Titel</Form.Label>
            <Form.Control type="text" name="title" onChange={onChange} ref={register({ required: true })}></Form.Control>
          </Form.Group>
          <Form.Group>
            <Form.Label>URL naar een afbeelding</Form.Label>
            <Form.Control type="text" name="imgUrl" onChange={onChange} ref={register}></Form.Control>
            </Form.Group>
          <Form.Group>
            <Form.Label>Inhoud</Form.Label>
            <Editor
              ref={node => register({name: 'content'})}
              apiKey="5j17gl15qabh112rj48lmha0tdncf9lq53glnndwzhw38qr7"
              init={{
                height: 500,
                menubar: false,
                plugins: [
                  'advlist autolink lists link image charmap print preview anchor',
                  'searchreplace visualblocks code fullscreen',
                  'insertdatetime media table paste imagetools wordcount'
                ],
                toolbar: 'insertfile undo redo | styleselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link image',
              }}
              onEditorChange={(content: any, editor: any) => {
                setValue('content', content);
              }}
            />
          </Form.Group>
          <Form.Group>
            <button className="btn btn-success" onClick={handleSubmit(onSubmit)} type="submit" disabled={isLoading}>
              Post toevoegen
            </button>
            {isLoading && <Spinner />}
          </Form.Group>
        </Form>
    )
}

export default PostEditor;
