import React, {useState, useEffect, Fragment} from 'react';
import { API, graphqlOperation } from 'aws-amplify'
import {listLPPosts} from '../../graphql/queries';
import {onCreateLPPost, onDeleteLPPost} from '../../graphql/subscriptions';
import Container from 'react-bootstrap/Container';
import Image from 'react-bootstrap/Image';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import DOMPurify from 'dompurify';
import {Subscription} from 'rxjs';
import PageViewer from '../../components/Utils/PageViewer';
import Spinner from '../../components/Utils/Spinner';
import { FacebookShareButton } from "react-share";
import { FacebookIcon } from "react-share";

const Home = () => {
    const [allPosts, setAllPosts] = useState<any[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true);
            const result: any = await API.graphql(graphqlOperation(listLPPosts));
            setAllPosts(result.data.listLPPosts.items);
            setIsLoading(false);
        }
        fetchData();
    }, []);

    // onCreate Listener
    useEffect(()=> {
        const createPostListener: Subscription = (API.graphql(graphqlOperation(onCreateLPPost)) as any).subscribe({
            next: (postData: any) => {
                const postItem = postData.value.data.onCreateLPPost;
                const prevPost = allPosts?.filter( (post: any) => post.id !== postItem.id);
                const updatedPosts: any[] = [postItem, ...prevPost];
                setAllPosts(updatedPosts);
            }
        });
    return () => createPostListener.unsubscribe();
    }, [allPosts]);

    // listener to every update
    // useEffect(() => {
    //     const updatePostListener: Subscription = (API.graphql(graphqlOperation(onUpdateLPPost)) as any).subscribe({
    //             next: (pageData: any) => {
    //                 console.log(pageData);
    //                 const updatePost = pageData.value.data.onUpdateLPPost;
    //                 const updatedPost = allPosts?.filter( (post: any) => post.id !== updatePost.id);
    //                 const updatedPosts: any[] = [...updatedPost, updatePost];
    //                 setAllPosts(updatedPosts);
    //             }
    //         });
    //     return () => updatePostListener.unsubscribe();
    // }, [])

    // onDelete Listener
    useEffect(() => {
        const deletePostListener: Subscription = (API.graphql(graphqlOperation(onDeleteLPPost)) as any).subscribe({
            next: (postData: any) => {
                const deletedPost = postData.value.data.onDeleteLPPost;
                const updatedPost: any[] = allPosts?.filter((post: any) => post.id !== deletedPost.id);
                setAllPosts(updatedPost);
            }
        })
    return () => deletePostListener.unsubscribe();
    }, [allPosts]);

    // sort function by Date
    const sortByDate = (a: any, b: any) =>  {
        if( a.updatedAt < b.updatedAt) {
            return 1;
        }
        if (a.updatedAt > b.updatedAt) {
            return -1;
        }
        return 0;
    }

    return (
        <Fragment>
            {/* <Container className="jumbotron mb-3 mt-3"> */}
                <PageViewer name="Home" classes="jumbotron mb-3 mt-3" />
            {/* </Container> */}
            {isLoading && <Spinner />}
            {/* Display posts */}
            {allPosts.sort(sortByDate).map((item, i) => {
                return (
                    <Container key={i} className="jumbotron mb-3 mt-3">
                        <div style={{float: 'right'}}>
                        <FacebookShareButton
                            url={'https://www.lexanprojects.be/posts/' + item.id}
                            quote={item.title}
                            hashtag={"#lexanprojects"}
                        >
                            <FacebookIcon size={32} round />
                        </FacebookShareButton>
                        </div>
                        
                        {(() => {
                            if(item.image) {
                                return (
                                    <Row>
                                        <Col md={2}>
                                            <Image fluid src={item.image} alt={item.name} style={{ width: '100%'}} />
                                        </Col>
                                        <Col>
                                            <div dangerouslySetInnerHTML={{__html:  DOMPurify.sanitize(item.content)}}></div>
                                        </Col>
                                    </Row>
                                )
                            } else {
                                return <div dangerouslySetInnerHTML={{__html:  DOMPurify.sanitize(item.content)}}></div>
                            }
                        })()}
                    </Container>
                )
            })}
        </Fragment>
        
    )
}

export default Home;