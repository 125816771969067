import React from 'react';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import Amplify from 'aws-amplify';


import Footer from './components/Footer';

import ImageCarousel from './components/Admin/ImageCarouselForm';

// Pages
import Admin from './pages/Admin';
import NotFound from './pages/NotFound';
import Main from './pages/Main';
import PostDetail from './components/PostDetail';
import './App.scss';
import awsconfig from './aws-exports';

Amplify.configure(awsconfig);

function App() {
  return (
      <Router>
        <Switch>
          <Route exact path="/" component={Main} />
          <Route exact path='/posts/:id' component={PostDetail} />
          <Route exact path="/admin" component={Admin} />
          <Route exact path="/*">
            <Redirect to="/" />
          </Route>
        </Switch>
        <Footer />
      </Router>
  );
}

export default App;
