import React, {useState, useEffect} from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { API, graphqlOperation } from 'aws-amplify'
import {listLPImageCarousels} from '../../graphql/queries';
import {onCreateLPImageCarousel, onDeleteLPImageCarousel} from '../../graphql/subscriptions';
import {Subscription} from 'rxjs';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Carousel from 'react-bootstrap/Carousel';

import Header from '../../components/Header';
import Navigation from '../../components/Navigation';

import Home from '../Home';
import Contact from '../Contact';

const Main = () => {
    const [allImages, setAllImages] = useState<any[]>([]);

    useEffect(() => {
        const fetchData = async () => {
            //setIsLoading(true);
            const result: any = await API.graphql(graphqlOperation(listLPImageCarousels));
            setAllImages(result.data.listLPImageCarousels.items);
            //setIsLoading(false);
        }
        fetchData();
    }, []);

    // onCreate Listener
    useEffect(()=> {
        const createImageListener: Subscription = (API.graphql(graphqlOperation(onCreateLPImageCarousel)) as any).subscribe({
            next: (postData: any) => {
                const postItem = postData.value.data.onCreateLPImageCarousel;
                const prevPost = allImages?.filter( (post: any) => post.id !== postItem.id);
                const updatedPosts: any[] = [postItem, ...prevPost];
                setAllImages(updatedPosts);
            }
        });
    return () => createImageListener.unsubscribe();
    }, [allImages]);

    // onDelete Listener
    useEffect(() => {
        const deleteImageListener: Subscription = (API.graphql(graphqlOperation(onDeleteLPImageCarousel)) as any).subscribe({
            next: (postData: any) => {
                const deletedPost = postData.value.data.onDeleteLPImageCarousel;
                const updatedPost: any[] = allImages?.filter((post: any) => post.id !== deletedPost.id);
                setAllImages(updatedPost);
            }
        })
    return () => deleteImageListener.unsubscribe();
    }, [allImages]);

    return (
        <Container fluid>
            <Router>
                <Container className="mb-3 mt-3">
                <Row>
                    <Col sm={6}>
                    <Header />
                    </Col>
                    <Col className="my-auto">
                        <Carousel>
                        {
                            allImages.map((p, i) => (
                                <Carousel.Item key={i}>
                            <img
                                className="d-block w-100"
                                key={i}
                                src={p.image}
                                alt={p.name}
                            /></Carousel.Item>
                            ))
                        }
                        </Carousel>
                    </Col>
                </Row>
                <Navigation />
                <Switch>
                    <Route exact path="/" component={Home} />
                    <Route exact path="/contact" component={Contact} />
                </Switch>
                </Container>
            </Router>
        </Container>
    )
}

export default Main;
