/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-west-1",
    "aws_cognito_identity_pool_id": "eu-west-1:ec2b2950-d47a-41df-b8dd-7b6d3546d6d6",
    "aws_cognito_region": "eu-west-1",
    "aws_user_pools_id": "eu-west-1_Txa15WqO9",
    "aws_user_pools_web_client_id": "5n0g9nv959878thnql5r2ja004",
    "oauth": {},
    "aws_user_files_s3_bucket": "lexanprojectsaaa9a9184e174771b9ca7e94dfa8cab5184314-dev",
    "aws_user_files_s3_bucket_region": "eu-west-1",
    "aws_appsync_graphqlEndpoint": "https://iptc4vgzbfgbhcopiiq4xmtxhy.appsync-api.eu-west-1.amazonaws.com/graphql",
    "aws_appsync_region": "eu-west-1",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-qgmvesw6avhwpfbwbkfrvcth3q"
};


export default awsmobile;
