import React, {useState, useEffect, Fragment} from 'react';
import { API, graphqlOperation } from 'aws-amplify';
import DOMPurify from 'dompurify';
import Container from 'react-bootstrap/Container';
import {getLPPageByName} from '../../../graphql/customQueries';
import {onUpdateLPPage} from '../../../graphql/subscriptions';
//import { Page } from '../../model/Page';
import Spinner from 'react-bootstrap/Spinner';

import {Subscription} from 'rxjs'

const PageViewer = (props: {name: string, classes: string}) => {
    const [page, setPage] = useState<null | any>();
    const [isLoading, setIsLoading] = useState<boolean>(false);

    // get specific page
    useEffect( () => {
        setIsLoading(true);
        const getPage = async (name: string) => {
            const result: any = await API.graphql(graphqlOperation(getLPPageByName, {name}));
            setPage(result.data.listLPPages.items[0]);
        }
        getPage(props.name);
        setIsLoading(false);
    }, [props.name]);

    // listener to every update
    useEffect(() => {
        const updatePageListener: Subscription = (API.graphql(graphqlOperation(onUpdateLPPage)) as any).subscribe({
                next: (pageData: any) => {
                    const updatePage = pageData.value.data.onUpdateLPPage;
                    setPage(updatePage);
                }
            });
        return () => updatePageListener.unsubscribe();
    }, [])

    if(isLoading) {
        return (<Spinner animation="border" role="status" variant="info" style={{width: "2rem", height: "2rem"}}>
        <span className="sr-only">Loading</span>
    </Spinner>);
    }

    return (
        <Fragment>
            {page != null && page.content && <Container className={props.classes}><div dangerouslySetInnerHTML={{__html:  DOMPurify.sanitize(page.content, { ADD_TAGS: ["iframe"], ADD_ATTR: ['allow', 'allowfullscreen', 'frameborder', 'scrolling'] })}}></div></Container>}
        </Fragment>
    )
}

export default PageViewer;