import React from 'react';
import Container from 'react-bootstrap/Container'
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import { Link } from 'react-router-dom';


const Navigation = () => {
    return (
        <Container className="mb-3 mt-3">
            <Navbar collapseOnSelect bg="dark" variant="dark" className="rounded" expand="lg">
            <Navbar.Brand href="/">
            Lexan Projects
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
            <Navbar.Collapse id="responsive-navbar-nav">
                <Nav className="ml-auto">
                    <Link className="nav-link" to="/">
                        Home
                    </Link>
                    <Link className="nav-link" to="/contact">
                        Contact
                    </Link>
                </Nav>
            </Navbar.Collapse>
            </Navbar>
        </Container>
    )
}

export default Navigation;