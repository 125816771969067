import React, {useState, useEffect} from 'react';
import Container from 'react-bootstrap/Container';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import {Subscription} from 'rxjs';
import { API, graphqlOperation } from 'aws-amplify';
import {listLPPages} from '../../../graphql/queries';
import {onCreateLPPage} from '../../../graphql/subscriptions';

//import AddPage from '../AddPage';
import PageEditor from '../PageEditor';
import Spinner from '../../Utils/Spinner';

const PageTab = () => {
    const [key, setKey] = useState<null | any>('Home');
    const [allPages, setAllPages] = useState<any[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    // get all pages to render the tabs
    useEffect(() => {
        setIsLoading(true);
        const fetchData = async () => {
            const result: any = await API.graphql(graphqlOperation(listLPPages));
            let pages: any[] = [];
            result.data.listLPPages.items.forEach((element: any) => {
                pages.push(element);
            });
            setAllPages(pages);
        };
        fetchData();
        setIsLoading(false);
    }, []);

    // onCreatePageListener
    useEffect(() => {
        const createPageListener: Subscription = (API.graphql(graphqlOperation(onCreateLPPage)) as any).subscribe({
                next: (pageData: any) => {
                    const newPage = pageData.value.data.onCreateLPPage;
                    const prevPage = allPages?.filter( page => page.id !== newPage.id);
                    const updatedPages = [newPage, ...prevPage];
                    setAllPages(updatedPages);
                }
            })
        //createPageListener();
        return () => createPageListener.unsubscribe()
    }, [allPages])

    return (
        <Container>
            {isLoading && <Spinner />}
            <Tabs activeKey={key} onSelect={(k: any) => setKey(k)} className="mb-3 mt-3">
                {/* <Tab eventKey="Toevoegen" title="Pagina Toevoegen">
                    <AddPage />
                </Tab> */}
                {allPages?.map((item, i) => {
                    return (<Tab key={i} eventKey={item.name} title={item.name}>
                        <PageEditor page={item.name} pageId={item.id} content={item.content} />
                    </Tab>);
                })}
            </Tabs>
        </Container>
    )
}

export default PageTab;
