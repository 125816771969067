import React, {Fragment, useState} from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Collapse from 'react-bootstrap/Collapse';
import Button from 'react-bootstrap/Button';

import { API, graphqlOperation } from 'aws-amplify';
import {deleteLPPost} from '../../../../graphql/mutations';
import EditPost from '../EditPost';

const Viewer = (props: any) => {
    const [open, setOpen] = useState(false);
    const onDeleteItemHandler = async (id: string) => {
        const input = {
            id: id
        };
        await API.graphql(graphqlOperation(deleteLPPost, {input}));
    }
    return (
        <Fragment>
            <Row key={props.id}>
                <Col xs={6}><p>{props.title}</p></Col>
                <Col xs={3}><Button className="btn btn-info"
                onClick={() => setOpen(!open)}
                aria-controls={props.id}
                aria-expanded={open}
                ><i className="fas fa-edit"></i></Button></Col>
                <Col xs={3}><p><button className="btn btn-danger" onClick={(e) => onDeleteItemHandler(props.id) }><i className="fas fa-trash-alt"></i></button></p></Col>
            </Row>
            <Collapse in={open}>
                <div id={props.id}>
                    <EditPost {...props.post}/>
                </div>
            </Collapse>
        </Fragment>
    )
}

export default Viewer;
