import React, {useState, useEffect} from 'react';
import { BrowserRouter as Router, Switch, Route, Link } from 'react-router-dom';

import { AmplifyAuthenticator, AmplifySignOut, AmplifySignIn } from '@aws-amplify/ui-react';
import { AuthState, onAuthUIStateChange } from '@aws-amplify/ui-components';

import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';

import PageTab from '../../components/Admin/PageTab';
import Post from '../../components/Admin/Post';
import ContentMaterial from '../../components/Admin/ContentMaterial';
import ImageCarousel from '../../components/Admin/ImageCarouselForm';

const Admin = () => {
    const [authState, setAuthState] = useState<AuthState>();
    const [user, setUser] = useState<any | undefined>();
    

    // Authentication
    useEffect(() => {
        onAuthUIStateChange((nextAuthState, authData) => {
            setAuthState(nextAuthState);
            setUser(authData)
        });
    }, []);
    console.log(user);
    return authState === AuthState.SignedIn && user ? (
        <Container fluid>
            <Router>
                <div>Hello, {user.attributes.email}</div>
                <AmplifySignOut />
                <Navbar bg="dark" variant="dark" className="mb-1 mt-1">
                    <Navbar.Brand href="/"><i className="fas fa-home"></i>Terug naar de site</Navbar.Brand>
                    <Nav className="ml-auto">
                        <Link className="nav-link" to="/admin/pages" >
                            Pagina
                        </Link>
                        <Link className="nav-link" to="/admin/posts" >
                            Posts
                        </Link>
                        <Link className="nav-link" to="/admin/carousel" >
                            Carousel
                        </Link>
                        <Link className="nav-link" to="/admin/materials" >
                            Materiaal
                        </Link>
                    </Nav>
                </Navbar>
                <Switch>
                    <Route exact path="/admin/pages" component={PageTab} />
                    <Route exact path="/admin/posts" component={Post} />
                    <Route exact path="/admin/materials" component={ContentMaterial} />
                    <Route exact path="/admin/carousel" component={ImageCarousel} />
                </Switch>
            </Router>
        </Container>
    ) : (
        <AmplifyAuthenticator>
            <AmplifySignIn
                slot="sign-in"
                hideSignUp
            ></AmplifySignIn>
        </AmplifyAuthenticator>
    );
}

export default Admin
