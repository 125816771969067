import React, { useEffect, useState } from 'react';
import { Storage, API, graphqlOperation } from 'aws-amplify';
import {createLPImageCarousel, deleteLPImageCarousel} from '../../graphql/mutations';
import {listLPImageCarousels} from '../../graphql/queries';
import { withAuthenticator } from '@aws-amplify/ui-react';
import {onCreateLPImageCarousel, onDeleteLPImageCarousel} from '../../graphql/subscriptions';
import {Subscription} from 'rxjs';

import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';

import conf from '../../aws-exports';

const {
    aws_user_files_s3_bucket_region: region,
    aws_user_files_s3_bucket: bucket
  } = conf;

const ImageCarouselForm = () => {
    const [file, updateFile] = useState(null) as any;
    const [imageName, updateImageName] = useState('')
    const [imagesCarousel, updateImagesCarousel] = useState<any[]>([]);
    useEffect(() => {
        listImagesCarousel();
    }, []);

    const listImagesCarousel = async () => {
        const images: any = await API.graphql(graphqlOperation(listLPImageCarousels));
        updateImagesCarousel(images.data.listLPImageCarousels.items);
    };

    // onCreate Listener
    useEffect(()=> {
        const createImageListener: Subscription = (API.graphql(graphqlOperation(onCreateLPImageCarousel)) as any).subscribe({
            next: (postData: any) => {
                const postItem = postData.value.data.onCreateLPImageCarousel;
                const prevPost = imagesCarousel?.filter( (post: any) => post.id !== postItem.id);
                const updatedPosts: any[] = [postItem, ...prevPost];
                updateImagesCarousel(updatedPosts);
            }
        });
    return () => createImageListener.unsubscribe();
    }, [imagesCarousel]);

    // onDelete Listener
    useEffect(() => {
        const deleteImageListener: Subscription = (API.graphql(graphqlOperation(onDeleteLPImageCarousel)) as any).subscribe({
            next: (postData: any) => {
                const deletedPost = postData.value.data.onDeleteLPImageCarousel;
                const updatedPost: any[] = imagesCarousel?.filter((post: any) => post.id !== deletedPost.id);
                updateImagesCarousel(updatedPost);
            }
        })
    return () => deleteImageListener.unsubscribe();
    }, [imagesCarousel]);

    const onChange = (event: any) => {
        const { target: { value, files } } = event
        const fileForUpload = files[0]
        updateImageName(fileForUpload.name.split(".")[0])
        updateFile(fileForUpload || value)
    };

    const createImageCarousel = async() => {
        if (file) {
            const extension = file?.name.split(".")[1]
            const { type: mimeType } = file
            const key = `images/${imageName}.${extension}`      
            const url = `https://${bucket}.s3.${region}.amazonaws.com/public/${key}`
            const inputData = { name: imageName , image: url }

            try {
                await Storage.put(key, file, {
                    contentType : mimeType
                });
                await API.graphql(graphqlOperation(createLPImageCarousel, {input: inputData}));
            } catch (error) {
                console.log('error: ', error);
            }
        }
    };

    const onDeleteItemHandler = async (id: string) => {
        const input = {
            id: id
        };
        await API.graphql(graphqlOperation(deleteLPImageCarousel, {input}));
    }

    return (
        <Container>
            <Form>
            <Form.Group controlId="exampleForm.ControlInput1">
                <Form.Label>Naam van de afbeelding</Form.Label>
                <Form.Control
                    type="text"
                    placeholder="Een naam" 
                    value={imageName}
                    onChange={e => updateImageName(e.target.value)} 
                />
            </Form.Group>
            <Form.File 
                id="custom-file"
                label="Afbeelding toevoegen aan Carousel"
                custom
                onChange={onChange}
            />
            <Button
                onClick={createImageCarousel}
            >Afbeelding toevoegen</Button>
            </Form>
            {imagesCarousel.map((item, i) => {
                return (
                    <Row key={item.id}>
                        <Col xs={4}><Image src={item.image} thumbnail /></Col>
                        <Col xs={6}><p>{item.name}</p></Col>
                        <Col xs={2}><p><button className="btn btn-danger" onClick={(e) => onDeleteItemHandler(item.id) }><i className="fas fa-trash-alt"></i></button></p></Col>
                    </Row>
                )
                //return <Viewer key={i} id={item.id} title={item.title} />
            })}
        </Container>
    )
}

export default withAuthenticator(ImageCarouselForm);
