import React, {useState} from 'react';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import {Storage} from "aws-amplify";
import { AccessLevel } from '@aws-amplify/ui-components'

import Spinner from '../../../Utils/Spinner';

const AddMaterial = (props: any) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const onChange = async(file: any) => {
    setIsLoading(true);
    let contentType = '';
    if(file.name.toString().match('/.(jpg|jpeg|png|gif)')) {
      contentType ='image/png';
    }
    const result: any = await Storage.put('material/' + file.name, file, {
      level: AccessLevel.Public,
      metadata: {title: file.name},
      contentType: contentType
    });
    props.setAllMaterial([...props.allMaterial, result]);
    setIsLoading(false);
  }
  return (
     <Container>
      <Form>
        <Form.File
          id="file"
          label="Voeg hier een bestand toe"
          custom
          accept="*/*"
          onChange={(e: any) => onChange(e.target.files[0])}
          disabled={isLoading}
        />
      </Form>
      {isLoading && <Spinner />}
    </Container>
  )
}

export default AddMaterial;