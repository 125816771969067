import React, {useState, useEffect, Fragment} from 'react';
import {Storage} from 'aws-amplify';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';

const ViewMaterial = (props: any) => {
  const [link, setLink] = useState<string>();

  useEffect(() => {
    const fecthMaterial =  async () => {
      const result: any = await Storage.get(props.storageKey);
      convertMaterialToLink(result);
    };
    fecthMaterial();
  }, [props.storageKey]);

  const convertMaterialToLink = (material: any) => {
    let modifiedString = material.toString().substring(0, material.toString().indexOf('?'));
    setLink(modifiedString);
  }
  return (
    <Fragment>
      <Row>
        <Col>{props.storageKey}</Col>
        <Col><a href={link} target="_blank" rel="noreferrer">Link</a></Col>
        <Col>
        <Button variant="danger" onClick={props.onDelete}>
          <i className="fas fa-trash"></i>
        </Button>
        </Col>
      </Row>
    </Fragment>
  )
}

export default ViewMaterial;
