/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createLPPage = /* GraphQL */ `
  mutation CreateLPPage(
    $input: CreateLPPageInput!
    $condition: ModelLPPageConditionInput
  ) {
    createLPPage(input: $input, condition: $condition) {
      id
      name
      content
      createdAt
      updatedAt
    }
  }
`;
export const updateLPPage = /* GraphQL */ `
  mutation UpdateLPPage(
    $input: UpdateLPPageInput!
    $condition: ModelLPPageConditionInput
  ) {
    updateLPPage(input: $input, condition: $condition) {
      id
      name
      content
      createdAt
      updatedAt
    }
  }
`;
export const deleteLPPage = /* GraphQL */ `
  mutation DeleteLPPage(
    $input: DeleteLPPageInput!
    $condition: ModelLPPageConditionInput
  ) {
    deleteLPPage(input: $input, condition: $condition) {
      id
      name
      content
      createdAt
      updatedAt
    }
  }
`;
export const createLPPost = /* GraphQL */ `
  mutation CreateLPPost(
    $input: CreateLPPostInput!
    $condition: ModelLPPostConditionInput
  ) {
    createLPPost(input: $input, condition: $condition) {
      id
      title
      image
      content
      createdAt
      updatedAt
    }
  }
`;
export const updateLPPost = /* GraphQL */ `
  mutation UpdateLPPost(
    $input: UpdateLPPostInput!
    $condition: ModelLPPostConditionInput
  ) {
    updateLPPost(input: $input, condition: $condition) {
      id
      title
      image
      content
      createdAt
      updatedAt
    }
  }
`;
export const deleteLPPost = /* GraphQL */ `
  mutation DeleteLPPost(
    $input: DeleteLPPostInput!
    $condition: ModelLPPostConditionInput
  ) {
    deleteLPPost(input: $input, condition: $condition) {
      id
      title
      image
      content
      createdAt
      updatedAt
    }
  }
`;
export const createLPImageCarousel = /* GraphQL */ `
  mutation CreateLPImageCarousel(
    $input: CreateLPImageCarouselInput!
    $condition: ModelLPImageCarouselConditionInput
  ) {
    createLPImageCarousel(input: $input, condition: $condition) {
      id
      name
      image
      createdAt
      updatedAt
    }
  }
`;
export const updateLPImageCarousel = /* GraphQL */ `
  mutation UpdateLPImageCarousel(
    $input: UpdateLPImageCarouselInput!
    $condition: ModelLPImageCarouselConditionInput
  ) {
    updateLPImageCarousel(input: $input, condition: $condition) {
      id
      name
      image
      createdAt
      updatedAt
    }
  }
`;
export const deleteLPImageCarousel = /* GraphQL */ `
  mutation DeleteLPImageCarousel(
    $input: DeleteLPImageCarouselInput!
    $condition: ModelLPImageCarouselConditionInput
  ) {
    deleteLPImageCarousel(input: $input, condition: $condition) {
      id
      name
      image
      createdAt
      updatedAt
    }
  }
`;
