// this file contains custom queries that doesn't be generated by aws-amplify

export const getLPPageByName = /* GraphQL */ `
query GetLPPageByName($name: String!) {
  listLPPages(filter: {
    name: {
      eq: $name
    }
  }){
    items {
      id
      name
      content
    }
  }
}
`;