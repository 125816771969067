import React, {Fragment, useState} from 'react';
import axios from 'axios';
import {useForm} from 'react-hook-form';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Alert from 'react-bootstrap/Alert';
import Spinner from 'react-bootstrap/Spinner';
import PageViewer from '../../components/Utils/PageViewer';

const Contact = () => {
    const { register, errors, handleSubmit, setValue, reset } = useForm();

  const [showMessage, setShowMessage] = useState(false);
  const [loading, setLoading] = useState(false);

  const onChange = (e: any) => {
    setValue(e.target.name, e.target.value);
  };

  const onSubmit = async (data: any) => {
    setLoading(true);
    console.log(data);
    data.destination = process.env.REACT_APP_FORM_SENTTO_EMAIL;
    data.from = process.env.REACT_APP_FORM_NOREPLY;
    try {
      await axios.post(process.env.REACT_APP_MAILSERVER + "send-mail", data);
      data.destination = data.email;
      data.subject = "Copie van je bericht: " + data.subject;
      await axios.post(process.env.REACT_APP_MAILSERVER + "send-mail", data);
    } catch (error) {
      console.error(error);
    }

    setLoading(false);
     if (!showMessage) {
      setShowMessage(true);
      setTimeout(() => setShowMessage(false), 5000);
    }
    onReset();
  }

  const onReset = () => {
    reset({
      name: "",
      subject: "",
      email: "",
      message: ""
    });
  }
    return (
        <Container className="mb-5 mt-3">
            <Container className="mb-3 mt-3 jumbotron">
                <Row>
                    <Col xs={12} md={12}>
                        <div className="text-center">
                            <h1>Contacteer me</h1>
                            <p>
                            {showMessage && (
                                <Alert variant="success">
                                <i className="fas fa-check-circle fa-9x"></i>
                                </Alert>
                            )}
                            </p>
                        </div>
                        <Container>
                            <Row>
                                <Col md={6} xs={12} style={{border: 'none'}}>
                                <PageViewer name="Contact" classes="border-0" />
                                </Col>
                                <Col md={6} xs={12}>
                                    <Form.Group>
                                        <Form.Control
                                        id="name"
                                        name="name"
                                        type="text"
                                        placeholder="John Doe"
                                        onChange={onChange}
                                        ref={register({
                                            required: true,
                                        })}
                                        />
                                        {errors.name && (
                                        <span>
                                            <i className="text-danger fas fa-exclamation-triangle"></i>
                                        </span>
                                        )}
                                    </Form.Group>
                                    <Form.Group>
                                        <Form.Control
                                        id="email"
                                        name="email"
                                        type="email"
                                        placeholder="email@example.com"
                                        onChange={onChange}
                                        ref={register({
                                            required: true,
                                            // eslint-disable-next-line
                                            pattern: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                                        })}
                                        />
                                        {errors.email && (
                                        <span>
                                            <i className="text-danger fas fa-exclamation-triangle"></i>
                                        </span>
                                        )}
                                    </Form.Group>
                                    <Form.Group>
                                        <Form.Control
                                        id="subject"
                                        name="subject"
                                        type="text"
                                        placeholder="Onderwerp"
                                        onChange={onChange}
                                        ref={register({
                                            required: true,
                                        })}
                                        />
                                        {errors.subject && (
                                        <span>
                                            <i className="text-danger fas fa-exclamation-triangle"></i>
                                        </span>
                                        )}
                                    </Form.Group>
                                    <Form.Group>
                                        <Form.Control
                                        as="textarea"

                                        id="message"
                                        name="message"
                                        placeholder="Omschrijving"
                                        onChange={onChange}
                                        ref={register({
                                            required: true,
                                        })}
                                        />
                                        {errors.message && (
                                        <span>
                                            <i className="text-danger fas fa-exclamation-triangle"></i>
                                        </span>
                                        )}
                                    </Form.Group>
                                    <Button variant="secondary" size="lg" block onClick={handleSubmit(onSubmit)} disabled={loading}>
                                                <i className="fas fa-paper-plane fa-2x"></i>&nbsp; Verzenden
                                    </Button>
                                    {/* <h1>-OF-</h1>
                                    <a href='tel:+32471517319' className="btn btn-secondary btn-block btn-lg">
                                            <i className="fas fa-phone fa-2x"></i>
                                    </a> */}
                                </Col>
                            </Row>
                            {loading && (
                            <Container className="text-center">
                            <Spinner animation="border" role="status" variant="info" style={{width: "5rem", height: "5rem"}}>
                                <span className="sr-only">Loading...</span>
                            </Spinner>
                            </Container>
                            )}
                        </Container>
                    </Col>
                </Row>
            </Container>
        </Container>
    )
}

export default Contact;
