import React from 'react';

import Container from 'react-bootstrap/Container';

import Image from 'react-bootstrap/Image';

//import header from '../../assets/Lexan-Logo.png';
import header from '../../assets/lexanprojects.png';

const Header = () => {
    return (
        <Container className="mt-3 mb-3">
            <Image fluid src={header} alt="LexanProjects" className="mx-auto d-block bg-white rounded" />
        </Container>
    
    )
}

export default Header