/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const onCreateLPPage = /* GraphQL */ `
  subscription OnCreateLPPage {
    onCreateLPPage {
      id
      name
      content
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateLPPage = /* GraphQL */ `
  subscription OnUpdateLPPage {
    onUpdateLPPage {
      id
      name
      content
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteLPPage = /* GraphQL */ `
  subscription OnDeleteLPPage {
    onDeleteLPPage {
      id
      name
      content
      createdAt
      updatedAt
    }
  }
`;
export const onCreateLPPost = /* GraphQL */ `
  subscription OnCreateLPPost {
    onCreateLPPost {
      id
      title
      image
      content
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateLPPost = /* GraphQL */ `
  subscription OnUpdateLPPost {
    onUpdateLPPost {
      id
      title
      image
      content
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteLPPost = /* GraphQL */ `
  subscription OnDeleteLPPost {
    onDeleteLPPost {
      id
      title
      image
      content
      createdAt
      updatedAt
    }
  }
`;
export const onCreateLPImageCarousel = /* GraphQL */ `
  subscription OnCreateLPImageCarousel {
    onCreateLPImageCarousel {
      id
      name
      image
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateLPImageCarousel = /* GraphQL */ `
  subscription OnUpdateLPImageCarousel {
    onUpdateLPImageCarousel {
      id
      name
      image
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteLPImageCarousel = /* GraphQL */ `
  subscription OnDeleteLPImageCarousel {
    onDeleteLPImageCarousel {
      id
      name
      image
      createdAt
      updatedAt
    }
  }
`;
