import React, {useState, useEffect} from 'react';
import { API, graphqlOperation } from 'aws-amplify'
import {getLPPost} from '../../graphql/queries';
import DOMPurify from 'dompurify';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image'

const PostDetail = (props: any) => {
    const { params: { id } } = props.match;

    const [post, setPost] = useState<any[]>([]) as any;
    useEffect(() => {
        const fetchData = async () => {
            const result: any = await API.graphql(graphqlOperation(getLPPost, {id: id}));
            setPost(result.data.getLPPost);
        }
        fetchData();
    }, [id]);
    return (
        <Container className="bg-secondary mb-3 mt-3">
            <h1 className="text-center">{post.title}</h1>
            {(() => {
                if(post.image) {
                    return (
                        <Row>
                            <Col md={2}>
                                <Image fluid src={post.image} alt={post.name} style={{ width: '100%'}} />
                            </Col>
                            <Col>
                                <div className="text-white" dangerouslySetInnerHTML={{__html:  DOMPurify.sanitize(post.content)}}></div>
                            </Col>
                        </Row>
                    )
                } else {
                    return <div className="text-white" dangerouslySetInnerHTML={{__html:  DOMPurify.sanitize(post.content)}}></div>
                }
            })()}
        </Container>
    )
}

export default PostDetail
