/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getLPPage = /* GraphQL */ `
  query GetLPPage($id: ID!) {
    getLPPage(id: $id) {
      id
      name
      content
      createdAt
      updatedAt
    }
  }
`;
export const listLPPages = /* GraphQL */ `
  query ListLPPages(
    $filter: ModelLPPageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listLPPages(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        content
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getLPPost = /* GraphQL */ `
  query GetLPPost($id: ID!) {
    getLPPost(id: $id) {
      id
      title
      image
      content
      createdAt
      updatedAt
    }
  }
`;
export const listLPPosts = /* GraphQL */ `
  query ListLPPosts(
    $filter: ModelLPPostFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listLPPosts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        title
        image
        content
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getLPImageCarousel = /* GraphQL */ `
  query GetLPImageCarousel($id: ID!) {
    getLPImageCarousel(id: $id) {
      id
      name
      image
      createdAt
      updatedAt
    }
  }
`;
export const listLPImageCarousels = /* GraphQL */ `
  query ListLPImageCarousels(
    $filter: ModelLPImageCarouselFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listLPImageCarousels(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        image
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
